/* src/App.css */
body, html {
  background-color: pink;
  height: 100%;
  font-family: Verdana, Geneva, sans-serif
}
.connectionStatus {
  padding-top: 20px;
  margin-left: 5%;
  font-size: 1.5em;
}
.subscriber {
  margin-left: 5%;
}
.publisher {
  float: right;
  margin-right: 10%;
}
.OTPublisherContainer {
  width: 80vh !important;
  height: 80vh !important;
}
#error {
  color: red;
}
